import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router, UrlTree } from '@angular/router';
import { DialogService } from '@ev-portals/ev/frontend/util';
import { BehaviorSubject } from 'rxjs';

import { AnalyticsService } from './analytics.service';
import { Environment, MenuName, Page, PageName } from './model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  #router = inject(Router);
  #dialogService = inject(DialogService);
  #environment = inject(Environment);
  activeLocale = inject(LOCALE_ID);

  #defaultActiveMenuName: MenuName = 'home';
  #_activeMenuName$ = new BehaviorSubject<MenuName>(this.#defaultActiveMenuName);
  activeMenuName$ = this.#_activeMenuName$.asObservable();

  #analyticsService = inject(AnalyticsService);
  // Track pages that should not be tracked automatically
  #manuallyTrackedPages: PageName[] = [
    // 'product-details',
    // 'privacy-policy',
  ];

  constructor() {
    this.#router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Note: We need this, as 'scrollPositionRestoration' doesn't work due to atom-shell
        this.scrollToTop();
        const activePage = this.#getPageByUrl(event.url);

        if (!activePage) {
          // Alert to support debugging, but only in non-prod environments
          if (this.#environment.environment !== 'prod') {
            this.#dialogService.alert(`[NavigationService] Page not found: ${event.url}`);
          }
          return;
        }

        this.#_activeMenuName$.next(activePage.menuName ?? this.#defaultActiveMenuName);

        // Track page view, if it's not manually tracked
        if (!this.#manuallyTrackedPages.includes(activePage.name)) {
          this.#analyticsService.viewPage(activePage.name, activePage.menuName);
        }
      }
    });
  }

  removeQueryParam(urlString: string, queryParamKey: string): string {
    // Create a URL object
    const url = new URL(urlString, 'http://dummy.com'); // Adding dummy base for relative URLs

    // Remove the sso parameter
    url.searchParams.delete(queryParamKey);

    // Return the updated URL
    return url.pathname + url.search;
  }

  #getPageByUrl(url: string): Page | undefined {
    // We need to reverse the pages array, because the first match will be returned (we're filtering out the more detailed urls first)
    return [...pages].reverse().find(({ urlRegexp }) => urlRegexp.test(url));
  }

  storeOriginalDestination(destinationUrl = this.#router.url): void {
    this.setOriginalDestination(destinationUrl);
  }

  navigateToOriginalDestination(): Promise<boolean> {
    // First let's get the url that the user originally wanted to access
    const originalDestination = this.getOriginalDestination() ?? '';
    this.removeOriginalDestination();
    // We are good to go, let's navigate to that original url
    return this.#router.navigateByUrl(originalDestination);
  }

  refreshPage(): void {
    window.location.reload();
  }

  navigateToTermsAndConditions(): void {
    const url = this.#router.serializeUrl(
      this.#router.createUrlTree([`/info/terms-and-conditions`]),
    );

    window.open(url, '_blank');
  }

  navigateToRequests(navSettings: NavigationExtras = {}): Promise<boolean> {
    return this.#router.navigate([`/requests/send`], navSettings);
  }

  navigateToRequestSample(productId: string): Promise<boolean> {
    const queryParams = {
      productId: productId,
      requestCategory: 'sample',
    };

    return this.navigateToRequests({ queryParams });
  }

  navigateToRequestPrice(productId: string): Promise<boolean> {
    const queryParams = {
      productId: productId,
      requestCategory: 'commercial',
    };

    return this.navigateToRequests({ queryParams });
  }

  navigateToFirstTimeOrder(productId: string): Promise<boolean> {
    const queryParams = {
      productId: productId,
      requestCategory: 'commercial',
    };

    return this.navigateToRequests({ queryParams });
  }

  navigateToHome(): Promise<boolean> {
    return this.#router.navigate(['']);
  }

  navigateToForbiddenPage(): Promise<boolean> {
    return this.#router.navigate(['/error/403']);
  }

  navigateToNotFoundPage(): Promise<boolean> {
    return this.#router.navigate(['/error/404']);
  }

  navigateToProducts(): Promise<boolean> {
    return this.#router.navigate(['/products']);
  }

  navigateToProductDetails(productId: string, slug?: string): Promise<boolean> {
    // use old url structure if slug is not available
    if (!slug) {
      return this.#router.navigate([`/products/${productId}`]);
    }

    return this.#router.navigate([`/products/${slug}/p/${productId}`]);
  }

  navigateToMyProfile(): Promise<boolean> {
    return this.#router.navigate(['/user/profile']);
  }

  navigateToUserAdmin(): Promise<boolean> {
    return this.#router.navigate(['/user/admin/users']);
  }

  navigateToProductAdmin(): Promise<boolean> {
    return this.#router.navigate(['/user/admin/products']);
  }

  navigateToOrderAdmin(): Promise<boolean> {
    return this.#router.navigate(['/user/admin/orders']);
  }

  navigateToContact(): Promise<boolean> {
    return this.#router.navigate(['/info/contact']);
  }

  navigateToCart(): Promise<boolean> {
    return this.#router.navigate(['/cart']);
  }

  scrollToTop(): void {
    document.querySelector('atom-shell')?.shadowRoot?.querySelector('.page')?.scrollTo(0, 0);
  }

  getUrlTreeByPageName(pageName: PageName): UrlTree {
    return this.#router.parseUrl(this.getUrlByPageName(pageName));
  }

  getUrlByPageName(pageName: PageName): string {
    const page = pages.find(page => page.name === pageName);

    if (!page || !page.url) {
      throw new Error(`Page not found: ${pageName}`);
    }

    return page.url;
  }

  navigateToOrderConfirmation(): Promise<boolean> {
    const url = this.getUrlByPageName('order-confirmation');
    return this.#router.navigate([url]);
  }

  getOriginalDestination(): string | null {
    return localStorage.getItem('originalDestination');
  }

  setOriginalDestination(destinationUrl: string): void {
    localStorage.setItem('originalDestination', destinationUrl);
  }

  removeOriginalDestination(): void {
    localStorage.removeItem('originalDestination');
  }
}

export const pages: Page[] = [
  // Regexp explanation: after the '/' query parameters are allowed, but not required
  { name: 'home', urlRegexp: /^\/(\?.*)?$/i, menuName: 'home' },
  { name: 'login', url: '/login', urlRegexp: /^\/login/, menuName: undefined },
  {
    name: 'error-403',
    url: '/error/403',
    urlRegexp: /^\/error\/403/,
    menuName: 'error',
  },
  {
    name: 'error-404',
    url: '/error/404',
    urlRegexp: /^\/error\/404/,
    menuName: 'error',
  },
  { name: 'cart', urlRegexp: /^\/cart/, menuName: 'cart' },
  { name: 'product-list', urlRegexp: /^\/products/, menuName: 'products' },
  {
    name: 'product-details',
    urlRegexp: /^\/products\/.*/, // matches all product details urls, als with slug and without slug
    menuName: 'products',
  },
  {
    name: 'pcf',
    urlRegexp: /^\/products\/pcf/,
    menuName: 'products',
  },
  { name: 'requests', urlRegexp: /^\/requests\/send/, menuName: 'requests' },
  {
    name: 'privacy-policy',
    urlRegexp: /^\/info\/privacy-policy/,
    menuName: 'privacy-policy',
  },
  {
    name: 'terms-of-use',
    urlRegexp: /^\/info\/terms-of-use/,
    menuName: 'terms-of-use',
  },
  {
    name: 'terms-and-conditions',
    urlRegexp: /^\/info\/terms-and-conditions/,
    menuName: 'terms-and-conditions',
  },
  { name: 'contact', urlRegexp: /^\/info\/contact/, menuName: 'contact' },
  {
    name: 'user-profile',
    urlRegexp: /^\/user\/profile/,
    menuName: 'user-profile',
  },
  {
    name: 'user-admin',
    urlRegexp: /^\/user\/admin\/users/,
    menuName: 'user-admin',
  },
  {
    name: 'product-admin',
    urlRegexp: /^\/user\/admin\/products/,
    menuName: 'product-admin',
  },
  {
    name: 'order-admin',
    urlRegexp: /^\/user\/admin\/order/,
    menuName: 'order-admin',
  },
  {
    name: 'order-confirmation',
    url: '/cart/confirmation',
    urlRegexp: /^\/cart\/confirmation/,
    menuName: undefined,
  },
];
