import { Injectable } from '@angular/core';
import { CoreFeedbackMessageService, Notification } from '@ev-portals/ev/frontend/util';

/**
 * Service for displaying feedback messages in the BaseLayoutComponent
 */
@Injectable({
  providedIn: 'root',
})
export class FeedbackMessageService extends CoreFeedbackMessageService {
  #errorMessageId = 'errorMessageId';

  addErrorNotification(): void {
    const notification: Notification = {
      id: this.#errorMessageId,
      type: 'error',
      title: this.DEFAULT_ERROR_MESSAGE,
      message: '',
      closable: true,
    };

    this.addNotification(notification);
  }
}
